import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import PreselectMultipleTracks from '../../components/common/navigation/PreselectMultipleTracks';
import PreselectTrack from '../../components/common/navigation/PreselectTrack';
import HistoryContainer from '../../components/History/HistoryContainer';
import HistoryFlightReport from '../flying/HistoryFlightReport';
import HistoryFlyingPage from '../flying/HistoryFlyingPage';
import { getUserData } from '../../redux/selectors/userData';
import { useFeatureFlag } from '../../hooks';
import FlightExplorer from '../flight-explorer';
import { useLocation } from 'react-router-dom';

const HistoryRoot = ({ match: { path } }) => {
  const userData = useSelector(getUserData);
  const queryParams = new URLSearchParams(useLocation().search);
  const is3dFRAvailable = queryParams.get('3dfr') != undefined;
  const { orgs = [] } = userData;
  const userOrgIds = orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const isFlightReportEnhancementsEnabled = useFeatureFlag(
    'flight-report-enhancements',
    userOrgIds
  );
  const useNewFlightDataService = useFeatureFlag('use-new-flightdata-service', userOrgIds);
  const insightsEnabled = useFeatureFlag('insights', userOrgIds);
  const preferSpiderSource = useFeatureFlag('prefer-spider-source-for-flightdata', userOrgIds);

  const flags = {
    insightsEnabled,
    preferSpiderSource,
    useNewFlightDataService
  };

  return (
    <Switch>
      <Route path={`${path}/flight-report-multiview`}>
        <PreselectMultipleTracks>
          <HistoryFlightReport />
        </PreselectMultipleTracks>
      </Route>
      <Route path={`${path}/:trackId/flight-explorer`}>
        <PreselectTrack>
          {isFlightReportEnhancementsEnabled ? (
            <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
          ) : (
            <HistoryFlightReport />
          )}
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:trackId/flight-report`}>
        <PreselectTrack>
          {isFlightReportEnhancementsEnabled ? (
            <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
          ) : (
            <HistoryFlightReport />
          )}
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:serialNumber/:bootcount/flight-explorer`}>
        <PreselectTrack>
          {isFlightReportEnhancementsEnabled ? (
            <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
          ) : (
            <HistoryFlightReport />
          )}
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:serialNumber/:bootcount/flight-report`}>
        <PreselectTrack>
          {isFlightReportEnhancementsEnabled ? (
            <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
          ) : (
            <HistoryFlightReport />
          )}
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:trackId`} component={HistoryFlyingPage} />
      <Route exact path={path} component={HistoryContainer} />
    </Switch>
  );
};

HistoryRoot.propTypes = {
  match: PropTypes.object
};

export default HistoryRoot;
